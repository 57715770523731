import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LargeTile } from './Tiles/LargeTile';
import { MediumTile } from './Tiles/MediumTile';
import { SmallTile } from './Tiles/SmallTile';

const useStyles = makeStyles((theme) => ({
	smallContainerOne: {
		height: '33%',
		borderRadius: '20px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	smallContainer: {
		height: '33%',
		borderRadius: '20px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	mediumContainerOne: {
		marginTop: '24px',
		height: '67%',
		borderRadius: '20px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	largeCard: {
		padding: 0,
		height: '67%',
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	smallCardOne: {
		padding: 0,
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	smallCardTwo: {
		padding: 0,
		width: '48.1%',
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	smallCardThree: {
		padding: 0,
		width: '48.1%',
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	smallCardFour: {
		padding: 0,
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	mediumCardOne: {
		padding: 0,
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	mediumCardTwo: {
		padding: 0,
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.25)',
	},
	cards: {
		display: 'flex',
		margin: '2rem 0',
		height: '40rem',
		borderRadius: '20px',
	},
}));

export const Mosaic = ({ large, medium, small }) => {
	const classes = useStyles();
	const [collapsed, setCollapsed] = useState({
		checked: false,
		tileName: null,
	});

	const handleMouseOver = (e) => {
		const name = e.currentTarget.getAttribute('name');

		setCollapsed({
			checked: true,
			titleName: name,
		});
	};

	const handleMouseLeave = (e) => {
		setCollapsed({
			checked: false,
			titleName: null,
		});
	};

	const handleClick = (e, slug) => {
		navigate(`/industries/${slug}`);
	};

	//This component is gated to only render when the appropriate number and combination of small, medium, and large tiles are provided.
	//If it is not rendering properly, ensure that you have provided 7 total tiles, 2 medium, 4 small, and 1 large in sanity

	return (
		medium.length === 2 &&
		small.length === 4 &&
		large.length === 1 && (
			<div className={classes.cards}>
				<Grid item xs={3}>
					<Grid
						item
						container
						direction='row'
						justifyContent='space-between'
						// style={{ marginTop: '24px' }}
						className={classes.smallContainerOne}>
						<SmallTile
							slug={small[0].node.slug.current}
							name={small[0].node.title}
							cardStyle={classes.smallCardOne}
							cardBackground={
								small[0].node.mosaicBackground.asset?.gatsbyImageData
							}
							title={small[0].node.title}
							collapsed={collapsed}
							cardContent={small[0].node._rawSynopsis}
							faIcon={small[0].node.faIcon}
							color={small[0].node.accentColor[0].hexValue}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justifyContent='space-between'
						className={classes.mediumContainerOne}>
						<MediumTile
							slug={medium[0].node.slug.current}
							name={medium[0].node.title}
							cardStyle={classes.mediumCardOne}
							cardBackground={
								medium[0].node.mosaicBackground?.asset?.gatsbyImageData
							}
							title={medium[0].node.title}
							collapsed={collapsed}
							cardContent={medium[0].node._rawSynopsis}
							faIcon={medium[0].node.faIcon}
							color={medium[0].node.accentColor[0].hexValue}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
					</Grid>
				</Grid>
				<Grid item xs={6} style={{ margin: '0 24px' }}>
					<LargeTile
						slug={large[0].node.slug.current}
						name={large[0].node.title}
						cardStyle={classes.largeCard}
						cardBackground={
							large[0].node.mosaicBackground.asset?.gatsbyImageData
						}
						title={large[0].node.title}
						faIcon={large[0].node.faIcon}
						color={large[0].node.accentColor[0].hexValue}
						collapsed={collapsed}
						cardContent={large[0].node._rawSynopsis}
						handleClick={handleClick}
						handleMouseLeave={handleMouseLeave}
						handleMouseOver={handleMouseOver}
					/>
					<Grid
						item
						container
						direction='row'
						justifyContent='space-between'
						style={{ marginTop: '24px', height: '33%' }}>
						<SmallTile
							slug={small[1].node.slug.current}
							name={small[1].node.title}
							cardStyle={classes.smallCardTwo}
							cardBackground={
								small[1].node.mosaicBackground.asset?.gatsbyImageData
							}
							title={small[1].node.title}
							faIcon={small[1].node.faIcon}
							color={small[1].node.accentColor[0].hexValue}
							collapsed={collapsed}
							cardContent={small[1].node._rawSynopsis}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
						<SmallTile
							slug={small[2].node.slug.current}
							name={small[2].node.title}
							cardStyle={classes.smallCardThree}
							cardBackground={
								small[2].node.mosaicBackground.asset?.gatsbyImageData
							}
							title={small[2].node.title}
							faIcon={small[2].node.faIcon}
							color={small[2].node.accentColor[0].hexValue}
							collapsed={collapsed}
							cardContent={small[2].node._rawSynopsis}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
					</Grid>
				</Grid>
				<Grid item xs={3} style={{ marginRight: '24px' }}>
					<Grid
						item
						container
						direction='row'
						justifyContent='space-between'
						className={classes.smallContainer}>
						<SmallTile
							slug={small[3].node.slug.current}
							name={small[3].node.title}
							cardStyle={classes.smallCardFour}
							cardBackground={
								small[3].node.mosaicBackground.asset?.gatsbyImageData
							}
							title={small[3].node.title}
							faIcon={small[3].node.faIcon}
							color={small[3].node.accentColor[0].hexValue}
							collapsed={collapsed}
							cardContent={small[3].node._rawSynopsis}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justifyContent='space-between'
						style={{ marginTop: '24px', height: '67%' }}>
						<MediumTile
							slug={medium[1].node.slug.current}
							name={medium[1].node.title}
							cardStyle={classes.mediumCardOne}
							cardBackground={
								medium[1].node.mosaicBackground.asset?.gatsbyImageData
							}
							title={medium[1].node.title}
							faIcon={medium[1].node.faIcon}
							color={medium[1].node.accentColor[0].hexValue}
							collapsed={collapsed}
							cardContent={medium[1].node._rawSynopsis}
							handleClick={handleClick}
							handleMouseLeave={handleMouseLeave}
							handleMouseOver={handleMouseOver}
						/>
					</Grid>
				</Grid>
			</div>
		)
	);
};
