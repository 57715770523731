import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	content: {
		height: '100%',
		transition: 'all 1s',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	header: {
		opacity: '1',
		color: '#002D5C',
		fontWeight: 600,
		lineHeight: 1.2,
		marginBottom: '10px',
	},
	text: {
		color: theme.darkGray,
	},
	image: {
		padding: 0,
		height: '100%',
		marginBottom: '2rem',
		backgroundSize: 'cover',
		borderRadius: '20px',
		overflow: 'hidden',
	},
	cards: {
		display: 'flex',
		margin: '2rem 0',
		width: '100%',
	},
}));

export const MobileMosaic = ({ industry }) => {
	const classes = useStyles();

	const handleClick = (e, slug) => {
		navigate(`/industries/${slug}`);
	};
	return (
		<div className={classes.cards}>
			<Grid item xs={12} style={{ margin: '0 10px' }}>
				<BgImage
					onClick={(e) => handleClick(e, industry.node.slug.current)}
					className={classes.image}
					image={industry.node.mosaicBackground?.asset?.gatsbyImageData}>
					<Grid
						container
						direction='column'
						alignItems='flex-start'
						justifyContent='flex-end'
						className={classes.content}>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.8)',
								width: '100%',
								marginTop: '200px',
								padding: '16px',
								minHeight: '220px',
							}}>
							<Grid container direction='row' className={classes.bottom}>
								<div className={classes.icon}>
									<FontAwesomeIcon
										icon={['fad', industry.node.faIcon]}
										style={{
											color:
												industry.node.accentColor[0]?.hexValue ?? '#002D5C',
											marginRight: '5px',
										}}
									/>
								</div>
								<div>
									<Typography variant='body1' className={classes.header}>
										{industry.node.title.replace('Software', '')}
									</Typography>
								</div>
							</Grid>
							<Grid container direction='row' className={classes.bottomContent}>
								<Typography>
									{industry.node._rawSynopsis.map((content, index) => (
										<PortableText
											key={index}
											content={content}
											className={classes.text}
											serializers={{
												normal: ({ children }) => {
													return (
														<Typography
															variant='body1'
															style={{
																margin: '0',
															}}>
															{children}
														</Typography>
													);
												},
											}}
										/>
									))}
								</Typography>
							</Grid>
						</div>
					</Grid>
				</BgImage>
			</Grid>
		</div>
	);
};
