import React from 'react';
import { BgImage } from 'gbimage-bridge';
import { blockContentToPlainText } from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	content: {
		height: '100%',
		transition: 'all 1s',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
		},
	},
	bottom: {
		width: '100%',
		padding: '16px 0 16px 16px',
		maxHeight: '100%',
	},
	bottomContent: {
		width: '100%',
		padding: '0 16px',
		maxHeight: '100%',
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '5px',
	},
	header: {
		opacity: '1',
		color: '#002D5C',
		fontWeight: 600,
	},
	text: {
		color: theme.darkGray,
		margin: '0',
		padding: '0',
		[theme.breakpoints.down('lg')]: {
			fontSize: '.9rem',
		},
	},
}));

export const LargeTile = ({
	slug,
	name,
	cardStyle,
	cardBackground,
	title,
	collapsed,
	cardContent,
	handleClick,
	handleMouseLeave,
	handleMouseOver,
	color,
	faIcon,
}) => {
	const classes = useStyles();
	return (
		<BgImage
			onClick={(e) => handleClick(e, slug)}
			onMouseOver={(e) => handleMouseOver(e)}
			onMouseLeave={(e) => handleMouseLeave(e)}
			name={name}
			className={cardStyle}
			image={cardBackground}>
			<Grid
				container
				direction='column'
				alignItems='flex-start'
				justify='flex-end'
				className={classes.content}>
				<div
					style={{
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						width: '100%',
					}}>
					<Grid container direction='row' className={classes.bottom}>
						<div className={classes.icon} style={{}}>
							<FontAwesomeIcon
								icon={['fad', faIcon]}
								style={{
									color: color ?? '#002D5C',
									width: '18px',
									height: '18px',
								}}
							/>
						</div>
						<Typography variant='body1' className={classes.header}>
							{title.replace('Software', '')}
						</Typography>
					</Grid>
					<Grid container direction='row' className={classes.bottomContent}>
						<Collapse in={title === collapsed.titleName && collapsed.checked}>
							{/* <Typography>
								{cardContent.map((content, index) => (
									<PortableText
										key={index}
										content={content}
										className={classes.text}
										serializers={{
											normal: ({ children }) => {
												return (
													<Typography
														variant='body1'
														style={{
															marginTop: '0',
															marginBottom: '16px',
														}}>
														{children}
													</Typography>
												);
											},
										}}
									/>
								))}
							</Typography> */}
							<Typography
								variant='body1'
								className={classes.text}
								style={{
									marginTop: '0',
									marginBottom: '16px',
								}}>
								{blockContentToPlainText(cardContent)}
							</Typography>
						</Collapse>
					</Grid>
				</div>
			</Grid>
		</BgImage>
	);
};
