import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import { Link, navigate } from 'gatsby';

import { Grid, Container, Typography } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { IndustrySearch } from './IndustrySearch';
import { Mosaic } from './Mosaic';
import { MobileMosaic } from './MobileMosaic';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.borderLightGray, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.borderLightGray, 0.25),
		},
		// marginRight: theme.spacing(5),
		marginLeft: 0,
		marginBottom: 0,
		marginTop: '1rem',
	},

	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: '14px 16px 13px 16px',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRight: 'none',
		borderRadius: '4px 0 0 4px',
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
		transition: theme.transitions.create('width'),
	},
	scrollingWrapper: {
		overflowX: 'scroll',
		whiteSpace: 'nowrap',
		'-webkit-overflow-scrolling': 'touch',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	header: {
		color: 'white',
		fontWeight: 600,
		[theme.breakpoints.down('lg')]: {
			fontSize: '2rem',
		},
	},
	allIndustries: {
		color: theme.white,
		fontWeight: 700,
		opacity: '.9',
		// margin: '0 1rem',
		transition: 'all.1s ease-in-out',
		'&:hover': {
			opacity: '1',
			'& $arrow': { transform: 'translateX(10px)' },
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0,
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
		fontWeight: 700,
	},
	icon: {
		opacity: 0.5,
		transition: 'all .3s',
		'&:hover': {
			transform: 'scale(1.04)',
			opacity: 1,
		},
	},
	industryBg: {
		padding: '12rem 0',
		[theme.breakpoints.down('sm')]: {
			padding: '6rem 0 4rem 0',
		},
	},
	sliderStyles: {
		marginBottom: '5rem',
		maxWidth: '100vw',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '2rem',
		},
	},
}));

const Industry = ({ industries, header, body, allLink }) => {
	const classes = useStyles();
	//state to track search term
	const [selectedSlug, setSelectedSlug] = useState(null);
	//stores the formatted mosaic industries
	const [industryMosaic, setIndustryMosaic] = useState([]);
	const [mobileMosaic, setMobileMosaic] = useState([]);
	const sliderRef = useRef(null);
	const searchRef = useRef(null);

	//function for breaking the filtered industry mosaic array into the right size array chunks
	//oooh, generator functions, fancy
	function* chunks(arr, n) {
		for (let i = 0; i < arr.length; i += n) {
			yield arr.slice(i, i + n);
		}
	}

	//sets the industry mosaic on mount by filtering by type, sorting them for if the boolean for the primary slide is true,
	// chunking the arrays, and mapping them into the array of objects that renders in the scroll
	useEffect(() => {
		let large = industries.edges
			.filter((industry) => industry.node.mosaicType[0]?.title === 'large')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);

		let medium = industries.edges
			.filter((industry) => industry.node.mosaicType[0]?.title === 'medium')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);

		let small = industries.edges
			.filter((industry) => industry.node.mosaicType[0]?.title === 'small')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);

		const mobile = industries.edges
			.filter((industry) => industry.node.mosaicType.length)
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);

		setMobileMosaic(mobile);

		large = [...chunks(large, 1)];
		medium = [...chunks(medium, 2)];
		small = [...chunks(small, 4)];

		const formatted = large.map((item, index) => ({
			large: item,
			medium: medium[index],
			small: small[index],
		}));

		setIndustryMosaic(formatted);
	}, [industries]);

	//search term change handler
	const handleChange = (e, value) => {
		const selected = industries.edges.filter(
			(industry) => industry.node.title === value
		);
		if (selected.length) {
			setSelectedSlug(selected[0].node.slug.current);
			navigate(`/industries/${selected[0].node.slug.current}`);
		} else {
			setSelectedSlug(null);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		selectedSlug && navigate(`/industries/${selectedSlug}`);
	};
	let settings = {
		className: 'center',
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 2,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const med = useMediaQuery('(max-width: 1000px)');
	return (
		industryMosaic.length && (
			<>
				<div className={classes.industryBg}>
					<Container>
						<Grid
							container
							spacing={1}
							direction='row'
							justifyContent='space-between'
							alignItems='center'>
							<Grid item xs={12} sm={9} md={5} lg={6}>
								<Typography variant='h3' className={classes.header}>
									{header}
								</Typography>
							</Grid>

							<Grid container item xs={12} md={6}>
								<Grid
									item
									sm={8}
									md={9}
									container
									direction='row'
									alignItems='center'>
									<IndustrySearch
										handleSubmit={handleSubmit}
										searchRef={searchRef}
										industries={industries}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid
									item
									container
									direction='row'
									alignItems='center'
									sm={4}
									md={3}>
									{' '}
									<span style={{ marginTop: '.5rem', color: 'white' }}>
										<FontAwesomeIcon
											className={classes.icon}
											icon={['fas', 'arrow-left']}
											style={{
												height: '32px',
												width: '32px',
												margin: '0 .5rem',
												cursor: 'pointer',
											}}
											onClick={(e) => sliderRef.current.slickPrev()}
										/>
										<FontAwesomeIcon
											className={classes.icon}
											icon={['fas', 'arrow-right']}
											style={{
												height: '32px',
												width: '32px',
												margin: '0 .5rem',
												cursor: 'pointer',
											}}
											onClick={(e) => sliderRef.current.slickNext()}
										/>
									</span>
								</Grid>
							</Grid>
						</Grid>
					</Container>
					<Slider
						{...settings}
						ref={sliderRef}
						className={classes.sliderStyles}>
						{med
							? mobileMosaic
									.filter((industry) => industry.node.mosaicBackground)
									.map((industry, index) => (
										<MobileMosaic key={index} industry={industry} />
									))
							: industryMosaic.map((industry, index) => (
									<Mosaic
										key={index}
										large={industry.large}
										medium={industry.medium}
										small={industry.small}
									/>
							  ))}
					</Slider>
					<Grid
						container
						direction={med ? 'column' : 'row'}
						alignItems='center'
						justifyContent='center'>
						<Grid item>
							<Link to='/industries' style={{ textDecoration: 'none' }}>
								<Typography variant='h5' className={classes.allIndustries}>
									View All Industries<span className={classes.arrow}>→</span>
								</Typography>
							</Link>
						</Grid>
						{/* <Grid item>
							<Link to='/industries' style={{ textDecoration: 'none' }}>
								<Typography variant='h5' className={classes.allIndustries}>
									Find Your Product<span className={classes.arrow}>→</span>
								</Typography>
							</Link>
						</Grid> */}
					</Grid>
				</div>
				{/* <div style={{ marginTop: '-11rem' }}>
				<WaveUpSVG height='213' width='100%' fill='white' />
			</div> */}
			</>
		)
	);
};

export default Industry;
